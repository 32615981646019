import React from 'react'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import translations from 'config/translations'
import Meta from 'theme/atoms/Meta'
import styled from 'styled-components'
import { ms } from 'modules/browser/const'
import { useProductList } from 'modules/products'
import config from 'config'
import { BannerProvider } from 'hooks/useBanner'
import { helmetJsonLdProp } from "react-schemaorg"
import { CollectionPage } from "schema-dts"

type Props = {
  location: {
    pathname: string
    key: string
  }
  data: {
    category: {
      story: any
      name: string
      headline?: string
      seoTitle: string
      canonicalUrl: string // TODO not used
      metaRobotsNoindex: boolean // TODO is currently string
      metaDescription: string
      parentCategory?: {
        name: string
        link: string
        parentCategory?: {
          name: string
          link: string
        }
      }
    }
    banner: {
      title: string
      objectID: string
      popupCtaLabel: string
      popupCtaUrl: string
      popupDescription: string
      popupHintText: string
      popupTitle: string
      subtitle: string
      backgroundColor: string
      dummy?: boolean
    } | null
  }
}

export default function Category(props: Props) {
  const { category } = props.data
  const { story } = category

  const crumbs: any = [{ label: 'Startseite', link: '/' }]

  if (category.name !== 'Bekleidung') {
    crumbs.push({ label: 'Bekleidung', link: config.prefix + '/shop/bekleidung/' })
  }

  if (category.parentCategory) {
    const parent = category.parentCategory
    const { name, link } = parent
    crumbs.push({ label: name, link })
    if (parent.parentCategory) {
      const { name, link } = parent.parentCategory
      crumbs.push({ label: name, link })
    }
  }

  crumbs.push({ label: category.name })

  const headline = props.data.category.headline || `${props.data.category.name} in großen Größen`
  return (
    <BannerProvider banner={props.data.banner}>
      <Layout breadcrumbs={crumbs} partialStateUpdates={story.partialStateUpdates}>
        <Meta
          pathname={props.location.pathname}
          title={
            category.seoTitle || translations.meta.category.title.replace('{category}', category.name)
          }
          description={
            category.metaDescription ||
            translations.meta.category.description.replace('{category}', category.name)
          }
          canonicalUrl={category.canonicalUrl}
          noFollow={category.metaRobotsNoindex}
          type="website"
          jsonLd={helmetJsonLdProp<CollectionPage>({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            name: category.seoTitle || translations.meta.category.title.replace('{category}', category.name),
            description:
              category.metaDescription ||
              translations.meta.category.description.replace('{category}', category.name),
            url: category.canonicalUrl
          })}
        />

        <Title>
          <h1>{headline}</h1>
          <NumHits show={category.name !== 'Bekleidung'} />
        </Title>

        <Story key={props.location.key} story={story} />
      </Layout>
    </BannerProvider>
  )
}

function NumHits({ show }: { show: boolean }) {
  const products = useProductList('fashion-product-list')
  const label = show ? `${products.numHits} ERGEBNISSE` : ` `
  return <div className="hits">{label}</div>
}

const Title = styled.div`
  color: #262626;
  text-align: center;
  margin-bottom: 30px;
  @media (min-width: ${ms.LAPTOP_XL}px) {
    margin: 0 25px;
    margin-bottom: 30px;
  }
  > h1 {
    font-family: 'Cormorant Garamond';
    color: #262626;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 36px;
    padding: 25px 20px;
    margin: 0;
    padding-bottom: 0px !important;

    @media (min-width: ${ms.LAPTOP}px) {
      font-size: 36px;
      letter-spacing: 0.64px;
      line-height: 42px;
      padding: 60px 0;
    }
  }

  > .hits {
    margin-top: 8px;
    color: #262626;
    font-family: Raleway;
    font-size: 11px;
    letter-spacing: 2.4px;
    line-height: 20px;
    height: 25px;
    margin-bottom: -30px !important;
    @media (min-width: ${ms.LAPTOP}px) {
      height: 40px;
    }
  }
`

export const query = graphql`
  query($id: String!) {
    category(id: { eq: $id }) {
      story
      name
      headline
      seoTitle
      canonicalUrl
      metaRobotsNoindex
      metaDescription
      parentCategory {
        name
        link
        parentCategory {
          name
          link
        }
      }
    }
    banner(productCategories: {in: [$id]}) {
      title
      objectID
      popupCtaLabel
      popupCtaUrl
      popupDescription
      popupHintText
      popupTitle
      subtitle
      backgroundColor
      dummy
    }
  }
`
